import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { Color } from '@/global/style/variables';
import styles from './AppLoginHeader.module.css';

const AppLoginHeader = () => {
  return (
    <div className={styles.appLoginHeader}>
      <SvgIcon title="Till Hallons startsida" icon="hallonLogo" color={Color.White} />
    </div>
  );
};

export default AppLoginHeader;
