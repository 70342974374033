import RadioLinkButton from '@/components/RadioLinkButton/RadioLinkButton';
import { FontWeight, SelectedAccountIdCookie } from '@/constants';
import { Color, spacing } from '@/global/style/variables';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import Button from '@/newComponents/Button/Button';
import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import { AccountUserRole, CmsSettings, RatorAccount } from '@/types';
import classNames from 'classnames';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { useAppContext } from '@/global/hooks/useAppContext';
import { Dispatch, SetStateAction, useEffect, useState, useTransition } from 'react';
import { ButtonColor } from '@/newComponents/Button/Button.utils';
import { logoutRedirect } from '@/global/utils/logout';
import useToastNotice from '@/global/hooks/useToastNotice';
import useScrollLockBody from '@/global/hooks/useScrollLockBody';
import { usePathname, useRouter } from 'next/navigation';
import styles from './MyPagesMenuOverlay.module.css';

type MyPagesMenuOverlayProps = {
  isOpen: boolean;
  isMobile: boolean;
  isApp?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MyPagesMenuOverlay = (props: MyPagesMenuOverlayProps) => {
  const { isOpen, isMobile, isApp = false, setIsOpen } = props;

  const [selectedRatorAccount, setSelectedRatorAccount] = useState<RatorAccount | null>(null);
  const [isSelectingAccount, startTransition] = useTransition();

  const pathname = usePathname();
  const router = useRouter();
  const toast = useToastNotice();
  const { account, accounts, roles } = useAppContext();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();

  const [blockScroll, allowScroll] = useScrollLockBody();

  useEffect(() => {
    if (!isMobile) return;
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [allowScroll, blockScroll, isOpen, isMobile]);

  const handleSelectAccount = (ratorAccount: RatorAccount) => {
    setSelectedRatorAccount(ratorAccount);
  };

  const handleSubmitAccount = () =>
    startTransition(() => {
      try {
        if (!selectedRatorAccount?.userId) throw Error('No account was selected');

        document.cookie = `${SelectedAccountIdCookie}=${selectedRatorAccount.userId}; path=/`;
        if (pathname === '/mina-sidor/abonnemang') {
          router.refresh();
        } else {
          router.push('/mina-sidor/abonnemang');
        }

        setIsOpen(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        toast({
          dataTestId: 'account-select-error-toast',
          preset: NoticeFieldPreset.Error,
          header: 'Något gick fel vid val av konto.',
        });
      }
    });

  return (
    <div
      className={classNames(styles.menuOverlay, {
        [styles.menuOverlayApp]: isApp,
        [styles.menuOverlayOpen]: isOpen,
        [styles.menuOverlayDesktop]: !isMobile,
      })}
    >
      <div className={styles.menuOverlayInner}>
        {roles.includes(AccountUserRole.Guest) ? (
          <Typography text="Inloggad som gästanvändare" color={Color.DarkGray1} />
        ) : (
          <button
            className={styles.styledButton}
            data-testid="go-to-my-pages-button"
            type="button"
            onClick={() => {
              window.location.href = '/mina-sidor/abonnemang';
              setIsOpen(false);
            }}
          >
            <Typography text={settings.headerGoToMyPages} fontWeight={FontWeight.SemiBold} />
            <SvgIcon size="small" icon="chevron-right" />
          </button>
        )}
        <Divider marginY={spacing.x05} />
        {account?.email && (
          <Typography
            text={getCmsTextWithValues(settings.headerLoggedInAs, { email: account.email })}
            color={Color.DarkGray1}
          />
        )}
        <Divider />
        <Button
          color={ButtonColor.Secondary}
          data-testid="log-out-button"
          label={settings.headerLogOut}
          onClick={() => logoutRedirect(true, isApp)}
        />

        {accounts && accounts.length > 1 && (
          <>
            <Divider marginY={spacing.x2} />
            <Typography type={TypographyType.ExpressiveL} text={settings.headerLoginAs} />

            <div
              className={classNames(styles.accountsWrapper, {
                [styles.accountsWrapperMobile]: isMobile,
                [styles.accountsWrapperDesktop]: !isMobile,
              })}
            >
              {accounts.map((ratorAccount: RatorAccount) => {
                const selected = selectedRatorAccount
                  ? selectedRatorAccount?.userId === ratorAccount.userId
                  : ratorAccount.userId === account?.userId;
                return (
                  <RadioLinkButton
                    data-testid={`radio-button-account-${ratorAccount.email}`}
                    checked={selected}
                    key={ratorAccount.userId}
                    aria-label="account-selection-radio-button"
                    label={ratorAccount.email}
                    selected={selected}
                    onChange={() => handleSelectAccount(ratorAccount)}
                  />
                );
              })}
            </div>

            <Divider marginY={spacing.x2} />
            <Button
              disabled={!selectedRatorAccount}
              data-testid="choose-account-button"
              label={settings.headerSelectAccount}
              isLoading={isSelectingAccount}
              onClick={handleSubmitAccount}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MyPagesMenuOverlay;
