'use client';

import { FontWeight, PageKey } from '@/constants';
import classNames from 'classnames';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { Color } from '@/global/style/variables';
import Typography from '@/newComponents/Typography/Typography';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { IconSize } from '@/newComponents/SvgIcon/SvgIcon.styled';

import CustomerNotification from '@/components/CustomerNotification/CustomerNotification';
import AppHeaderIcon from '@/global/layouts/parts/Header/AppHeader/AppHeaderIcon/AppHeaderIcon';
import { getBackRoute } from '@/global/layouts/parts/Header/AppHeader/AppHeader.utils';
import MyPagesMenuOverlay from '@/global/layouts/parts/Header/HeaderMain/MyPagesMenu/MyPagesMenuOverlay/MyPagesMenuOverlay';
import AppLoginHeader from '@/global/layouts/parts/Header/AppHeader/AppLoginHeader/AppLoginHeader';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { Brand, CmsSettings } from '@/types';
import { useAppContext } from '@/global/hooks/useAppContext';
import styles from './AppHeader.module.css';

const basePageKeys = [PageKey.Abonnemang, PageKey.Betalnigar, PageKey.Kontoinstallningar];
const pageLabels: Partial<Record<PageKey, string>> = {
  [PageKey.Abonnemang]: 'Abonnemang',
  [PageKey.Betalnigar]: 'Betalningar',
  [PageKey.Kontoinstallningar]: 'Kontoinställningar',
  [PageKey.Extrausers]: 'Extra användare',
  [PageKey.ExtrauserSales]: 'Extra användare',
  [PageKey.Addsurf]: 'Extra surf',
  [PageKey.ExtraSurf]: 'Extra surf',
  [PageKey.Bytabonnemang]: 'Ändra abonnemang',
  [PageKey.Paus]: 'Pausa abonnemang',
  [PageKey.Bestallsim]: 'Beställ SIM/eSIM',
};

type AppHeaderProps = {
  pageKey?: PageKey;
};

const AppHeader = (props: AppHeaderProps) => {
  const { pageKey } = props;

  const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);

  const params = useParams();
  const pathname = usePathname();
  const router = useRouter();
  const { pageBrand } = useAppContext();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const supportLink = (
    pageBrand === Brand.B2C ? settings.headerB2CcomplementaryNavigation : settings.headerB2BcomplementaryNavigation
  )[0].content;

  const isBasePage = pageKey && basePageKeys.includes(pageKey);
  const backRoute = getBackRoute(pageKey, params?.subscriptionid?.toString());

  useEffect(() => {
    if (logoutMenuOpen) {
      setLogoutMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (pathname?.includes('identify') || pathname === '/mina-sidor/konton') {
    return <AppLoginHeader />;
  }

  const header = logoutMenuOpen
    ? settings.headerLogOut
    : pageLabels[pageKey as PageKey] ?? pageLabels[PageKey.Abonnemang];

  return (
    <>
      <header className={styles.appHeader}>
        <div className={styles.appHeaderInner}>
          <button
            className={classNames(styles.headerIconButton, { [styles.headerIconButtonActive]: logoutMenuOpen })}
            data-testid="app-back-button"
            onClick={() => {
              if (isBasePage) {
                setLogoutMenuOpen(!logoutMenuOpen);
              } else if (backRoute) {
                router.push(backRoute.route);
              }
            }}
          >
            <AppHeaderIcon dropDownVisible={logoutMenuOpen} isBasePage={isBasePage} />
            <Typography
              type={TypographyType.Micro}
              fontWeight={FontWeight.Medium}
              color={Color.White}
              text={logoutMenuOpen ? 'Avbryt' : backRoute?.label || settings.headerLogOut}
            />
          </button>

          <Typography
            type={TypographyType.ExpressiveL}
            elementAs="h1"
            color={Color.White}
            text={header}
            align="center"
          />

          <button
            data-testid="app-support-button"
            className={styles.headerIconButton}
            onClick={() => router.push(supportLink.link.url)}
          >
            <SvgIcon icon={supportLink.icon} color={Color.White} size={IconSize.Small} />
            <Typography
              type={TypographyType.Micro}
              color={Color.White}
              fontWeight={FontWeight.Medium}
              text={supportLink.text}
            />
          </button>
        </div>
      </header>

      <div className={styles.appHeaderSpacer} />

      <MyPagesMenuOverlay isMobile isApp isOpen={logoutMenuOpen} setIsOpen={setLogoutMenuOpen} />
      <CustomerNotification />
    </>
  );
};

export default AppHeader;
