import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import useScrollLockBody from '@/global/hooks/useScrollLockBody';
import MainIconLink from '@/global/layouts/parts/Header/HeaderMain/MainIconLink/MainIconLink';
import { breakpoints } from '@/global/style/breakpoint';

import HamburgerMenuOverlay from '@/global/layouts/parts/Header/HeaderMain/HamburgerMenu/HamburgerMenuOverlay/HamburgerMenuOverlay';
import { HeaderComplementaryNavigation, HeaderLink } from '@/types';
import styles from './HamburgerMenu.module.css';

type HamburgerMenuProps = {
  mainLinks: HeaderLink[];
  mainIcons: HeaderComplementaryNavigation[];
  setHamburgerMenuIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ignoreOutsideClickClassName = 'ignore-outside-click-hamburger';

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const { mainLinks, mainIcons, setHamburgerMenuIsOpen } = props;
  const [isOpen, _setIsOpen] = useState<boolean>(false);
  const setIsOpen = (_isOpen: boolean) => {
    _setIsOpen(_isOpen);
    setHamburgerMenuIsOpen(_isOpen);
  };

  const { hasActiveCart } = useAppContext();
  const isMobileSm = useMatchMedia(`(max-width:${breakpoints.xs})`);
  const [blockScroll, allowScroll] = useScrollLockBody();

  useEffect(() => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [allowScroll, blockScroll, isOpen]);

  return (
    <div className={styles.hamburgerMenuWrapper}>
      <div className={styles.mainIconLinkContainer}>
        {hasActiveCart && isMobileSm && !isOpen && <div className={styles.activeCartIndicator} />}
        <MainIconLink
          data-testid="hamburger-menu"
          active={isOpen}
          isOpen={isOpen}
          iconLink={{
            link: {
              url: '*',
              name: '',
              target: null,
            },
            icon: 'runner',
            text: 'Meny',
            type: 'link',
          }}
          customIcon="animated-menu"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          ignoreOutsideClickClassName={ignoreOutsideClickClassName}
          ignoreOutsideClick
        />
      </div>

      {isOpen && (
        <HamburgerMenuOverlay
          mainIcons={mainIcons}
          mainLinks={mainLinks}
          setIsOpen={setIsOpen}
          ignoreOutsideClickClassName={ignoreOutsideClickClassName}
        />
      )}
    </div>
  );
};

export default HamburgerMenu;
