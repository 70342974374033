import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import classNames from 'classnames';
import Link from 'next/link';
import { FontWeight } from '@/constants';
import styles from './AppTabBar.module.css';

enum AppSection {
  None = 'none',
  Subscription = 'abonnemang',
  Payment = 'betalningar',
  AccountSettings = 'kontoinstallningar',
}
const footerItems: { id: AppSection; label: string; url: string; icon: IconKey }[] = [
  {
    id: AppSection.Subscription,
    label: 'Abonnemang',
    url: '/mina-sidor/abonnemang',
    icon: 'mobile',
  },
  {
    id: AppSection.Payment,
    label: 'Betalningar',
    url: '/mina-sidor/betalningar',
    icon: 'card',
  },
  {
    id: AppSection.AccountSettings,
    label: 'Konto',
    url: '/mina-sidor/kontoinstallningar',
    icon: 'details',
  },
];

const AppTabBar = () => {
  const pathname = usePathname();

  const currentSection = useMemo(() => {
    const path = pathname ?? '/';

    switch (true) {
      case path === '/mina-sidor/betalningar':
      case path.match(/\/(mina-sidor)\/(betalningar)\/(\d*)$/g) !== null:
        return AppSection.Payment;
      case path === '/mina-sidor/kontoinstallningar':
        return AppSection.AccountSettings;
      case path.includes('/mina-sidor'):
        return AppSection.Subscription;
      default:
        return AppSection.None;
    }
  }, [pathname]);

  return (
    <footer className={styles.appTabBar}>
      <ul className={classNames(styles.appTabBarItems, styles.activeIndicator, styles[`${currentSection}Active`])}>
        {footerItems.map((item) => {
          const isActive = currentSection === item.id;
          return (
            <Link href={item.url} key={item.label} className={styles.appTabBarLinkItem}>
              <li className={classNames(styles.appTabBarItem, { [styles.active]: isActive })}>
                <SvgIcon icon={item.icon} />
                <Typography
                  text={item.label}
                  type={TypographyType.Micro}
                  fontWeight={isActive ? FontWeight.SemiBold : FontWeight.Medium}
                />
              </li>
            </Link>
          );
        })}
      </ul>
    </footer>
  );
};

export default AppTabBar;
