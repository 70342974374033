'use client';

import NoticeField from '@/newComponents/NoticeField/NoticeField';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import { CmsSettings, NotificationType } from '@/types';
import { usePathname } from 'next/navigation';
import React from 'react';

import NotificationHelper from '@/global/utils/NotificationHelper';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import classNames from 'classnames';
import styles from './CustomerNotification.module.css';

const mapNotificationType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.IssueCritical:
      return NoticeFieldPreset.Error;
    case NotificationType.IssueMinor:
      return NoticeFieldPreset.Warning;
    case NotificationType.Ok:
      return NoticeFieldPreset.Success;
    case NotificationType.Information:
    default:
      return NoticeFieldPreset.Notice;
  }
};

const CustomerNotification = () => {
  const pathname = usePathname() ?? '/';
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const notificationBlockList = settings.notificationBlockList ?? null;

  if (!notificationBlockList) return null;

  return notificationBlockList.map((notification, index) => {
    if (notification.content) {
      const { notificationType, notificationHeadline, notificationBody, notificationShowOnPages } =
        notification.content;

      if (!NotificationHelper.shouldShowNotification(notificationShowOnPages?.$values, pathname)) {
        return null;
      }

      const preset = mapNotificationType(notificationType);

      return (
        <div
          key={`notice-field-basic-${index.toString()}`}
          className={classNames(styles.customerNotificationWrapper, styles[preset.toLocaleLowerCase()])}
        >
          <div className={styles.customerNotificationInner}>
            <NoticeField
              header={notificationHeadline}
              text={notificationBody}
              data-testid="customer-notification"
              preset={preset}
              showBorder={false}
            />
          </div>
        </div>
      );
    }
    return null;
  });
};

export default CustomerNotification;
