import { Color } from '@/global/style/variables';
import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { IconSize } from '@/newComponents/SvgIcon/SvgIcon.styled';

type AppHeaderIconProps = {
  dropDownVisible: boolean;
  isBasePage?: boolean;
};

const AppHeaderIcon = (props: AppHeaderIconProps) => {
  const { dropDownVisible, isBasePage } = props;

  let iconName: IconKey = 'chevron-left';

  if (isBasePage) {
    if (dropDownVisible) {
      iconName = 'cross';
    } else {
      iconName = 'logout';
    }
  }

  return <SvgIcon icon={iconName} color={Color.White} size={IconSize.Small} />;
};

export default AppHeaderIcon;
