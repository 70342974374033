import { FontWeight } from '@/constants';
import { useClickOutside } from '@/global/hooks/useClickOutside';
import { Color } from '@/global/style/variables';
import Divider from '@/newComponents/Divider/Divider';
import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { HeaderComplementaryNavigation, HeaderLink } from '@/types';
import { Fragment } from 'react';
import { useAppContext } from '@/global/hooks/useAppContext';
import classNames from 'classnames';
import LinkLookingButton from '@/components/LinkLookingButton/LinkLookingButton';
import styles from './HamburgerMenuOverlay.module.css';
import hamburgerMenuStyles from '../HamburgerMenu.module.css';

type HamburgerMenuOverlayProps = {
  mainLinks: HeaderLink[];
  mainIcons: HeaderComplementaryNavigation[];
  setIsOpen: (isOpen: boolean) => void;
  ignoreOutsideClickClassName: string;
};

const HamburgerMenuOverlay = (props: HamburgerMenuOverlayProps) => {
  const { mainLinks, mainIcons, setIsOpen, ignoreOutsideClickClassName } = props;

  const { hasActiveCart } = useAppContext();
  const menuOverlayRef = useClickOutside<HTMLDivElement>(() => setIsOpen(false), ignoreOutsideClickClassName);

  const shouldShowActiveCartIndicator = (mainIcon: HeaderComplementaryNavigation) =>
    (mainIcon.type === 'cart-b2c' || mainIcon.type === 'cart-b2b') && hasActiveCart;

  return (
    <div ref={menuOverlayRef} className={classNames(styles.hamburgerMenuOverlay, ignoreOutsideClickClassName)}>
      <ul className={styles.hamburgerMenuContainer}>
        <Divider marginY={0} />
        {mainLinks.map((mainLink) => (
          <Fragment key={mainLink.link.url}>
            <LinkLookingButton
              text={<Typography text={mainLink.text} fontWeight={FontWeight.SemiBold} />}
              className={styles.menuLink}
              onClick={() => {
                setIsOpen(false);
                window.location.href = mainLink.link.url;
              }}
              data-testid={`hamburger-link${mainLink.link.url}`}
            />
            <Divider color={Color.Gray2} marginY={0} />
          </Fragment>
        ))}

        {mainIcons.map((mainIcon) => (
          <Fragment key={mainIcon.icon}>
            <div className={styles.iconContainer}>
              {shouldShowActiveCartIndicator(mainIcon) && <div className={hamburgerMenuStyles.activeCartIndicator} />}
              <LinkLookingButton
                text={mainIcon.text}
                className={classNames(styles.menuLink, styles.menuLinkIcon)}
                onClick={() => {
                  window.location.href = mainIcon.link.url;
                }}
                iconKey={mainIcon.icon as IconKey}
                leftIcon
                data-testid={`hamburger-link${mainIcon.link.url}`}
              />
            </div>
            <Divider color={Color.Gray2} marginY={0} />
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default HamburgerMenuOverlay;
