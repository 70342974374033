import { type HeaderIconLink } from '@/types';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

import { useClickOutside } from '@/global/hooks/useClickOutside';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import MainIconLink from '@/global/layouts/parts/Header/HeaderMain/MainIconLink/MainIconLink';
import { breakpoints } from '@/global/style/breakpoint';

import MyPagesMenuOverlay from './MyPagesMenuOverlay/MyPagesMenuOverlay';

type MyPagesMenuProps = {
  myPagesIconLink: HeaderIconLink;
  shouldDisableLinkActiveState: boolean;
};

const MyPagesMenu = (props: MyPagesMenuProps) => {
  const { myPagesIconLink, shouldDisableLinkActiveState } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuOverlayRef = useClickOutside<HTMLLIElement>(() => setIsOpen(false));

  const pathname = usePathname();
  const isMobile = useMatchMedia(`(max-width:${breakpoints.sm})`);
  const { icon, text } = myPagesIconLink;

  return (
    <MainIconLink
      data-testid="my-pages-menu"
      ref={menuOverlayRef}
      active={(pathname?.includes(myPagesIconLink.link.url) || isOpen) && !shouldDisableLinkActiveState}
      isOpen={isOpen}
      iconLink={{
        link: {
          name: '',
          url: '*',
          target: null,
        },
        text,
        icon,
      }}
      secondaryIcon="chevron-down"
      shouldRotateSecondaryIcon
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      ignoreOutsideClick
    >
      <MyPagesMenuOverlay isOpen={isOpen} isMobile={isMobile} setIsOpen={setIsOpen} />
    </MainIconLink>
  );
};

export default MyPagesMenu;
