import(/* webpackMode: "eager" */ "/src/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.8_supports-color@8.1.1__@opentelemetry+api@1.7.0_react-do_0836945c44c55af46d0b2175e0dacf67/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/src/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.8_supports-color@8.1.1__@opentelemetry+api@1.7.0_react-do_0836945c44c55af46d0b2175e0dacf67/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/src/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.8_supports-color@8.1.1__@opentelemetry+api@1.7.0_react-do_0836945c44c55af46d0b2175e0dacf67/node_modules/next/font/local/target.css?{\"path\":\"src/app/_lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--bagoss-standard\",\"src\":[{\"path\":\"../../../public/static/fonts/BagossStandard-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/static/fonts/BagossStandard-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/static/fonts/BagossStandard-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/static/fonts/BagossStandard-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/static/fonts/BagossStandard-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../public/static/fonts/BagossStandard-SemiBold.woff\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"bagoss\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/src/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/src/src/app/_lib/components/ApplicationInsights.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/components/AppRouterFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/components/AppRouterHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/globalAppStyles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/src/src/global/style/Color.module.css");
;
import(/* webpackMode: "eager" */ "/src/src/global/style/root.css");
;
import(/* webpackMode: "eager" */ "/src/src/global/style/Layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/global/utils/HtmlFromCMS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/Card/Card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/Divider/Divider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/SvgIcon/SvgIcon.tsx");
;
import(/* webpackMode: "eager" */ "/src/src/newComponents/Typography/Typography.module.css");
