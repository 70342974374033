import { PageKey } from '@/constants';

type AppHeaderBackRoute = {
  route: string;
  label: string;
};
export const getBackRoute = (pageKey?: PageKey, subscriptionId?: string | null): AppHeaderBackRoute | null => {
  switch (pageKey) {
    case PageKey.Addsurf:
    case PageKey.ExtraSurf:
    case PageKey.Aktiveraesim:
    case PageKey.Avsluta:
    case PageKey.Bestallsim:
    case PageKey.Bytabonnemang:
    case PageKey.Cancelled:
    case PageKey.Extrausers:
    case PageKey.Paus:
      return { route: `/mina-sidor/${subscriptionId}`, label: 'Tillbaka' };
    case PageKey.Kvitto:
      return { route: '/mina-sidor/betalningar', label: 'Tillbaka' };
    case PageKey.Abonnemangsdetalj:
    case PageKey.ExtrauserSales:
      return { route: '/mina-sidor', label: 'Tillbaka' };
    default:
      return null;
  }
};
