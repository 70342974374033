'use client';

import { CmsSettings, HeaderComplementaryNavigation, HeaderIconLink, HeaderLink } from '@/types';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import useWindowWidth from '@/global/hooks/useWindowWidth';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import HeaderBrand from '@/global/layouts/parts/Header/HeaderBrand/HeaderBrand';
import HeaderMain from '@/global/layouts/parts/Header/HeaderMain/HeaderMain';
import HeaderSecondary from '@/global/layouts/parts/Header/HeaderSecondary/HeaderSecondary';
import { breakpointsValues } from '@/global/style/breakpoint';

import CustomerNotification from '@/components/CustomerNotification/CustomerNotification';

import Typography from '@/newComponents/Typography/Typography';
import { Color } from '@/global/style/variables';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { SkipNavContent } from '@/global/layouts/parts/Header/Header.styled';
import SessionProvider from '@/app/_lib/providers/SessionProvider';
import { getComplementaryIcons, getLoginIcon, getMainLinks, getMyPagesIcon, getSubLinks } from './Header.utils';

type HeaderProps = {
  showBrandSection?: boolean;
  isMinimal?: boolean;
  loggedIn?: boolean;
};

const Header = (props: HeaderProps) => {
  const { loggedIn = null, showBrandSection = false, isMinimal = false } = props;

  const { pageBrand, isApp, hasActiveCart } = useAppContext();

  const pathName = usePathname() ?? '';
  const [windowWidth] = useWindowWidth();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();

  const [mainLinks, setMainLinks] = useState<HeaderLink[]>(getMainLinks(pageBrand, settings));
  const [menuIcons, setMenuIcons] = useState<HeaderComplementaryNavigation[]>(
    getComplementaryIcons(pageBrand, settings)
  );
  const [loginIconLink, setLoginIconLink] = useState<HeaderIconLink | null>(getLoginIcon(pageBrand, settings));
  const [myPagesIconLink, setMyPagesIconLink] = useState<HeaderIconLink | null>(getMyPagesIcon(pageBrand, settings));
  const [subLinks, setSubLinks] = useState<HeaderLink[] | null>(getSubLinks(mainLinks, pathName, settings));

  const isSmallScreen = windowWidth < breakpointsValues.md;
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const prevScrollpos = useRef(typeof window === 'undefined' ? 0 : window.scrollY);
  const topOfPageOffset = showBrandSection ? 25 : 0;
  const showHeaderSecondary = subLinks && subLinks.length > 0 && !isMinimal;

  // Tracks sticky header
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Header is not sticky on larger screens
      if (!isSmallScreen) {
        setIsHeaderSticky(false);
      }
      // Header is not sticky on top of page
      else if (currentScrollPos <= topOfPageOffset) {
        setIsHeaderSticky(false);
      }
      // Header is not sticky when scrolling down
      else if (prevScrollpos.current < currentScrollPos) {
        setIsHeaderSticky(false);
      }
      // Header is sticky
      else {
        setIsHeaderSticky(true);
      }

      prevScrollpos.current = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSmallScreen, topOfPageOffset]);

  useEffect(() => {
    setMainLinks(getMainLinks(pageBrand, settings));
    setMenuIcons(getComplementaryIcons(pageBrand, settings));
    setLoginIconLink(getLoginIcon(pageBrand, settings));
    setMyPagesIconLink(getMyPagesIcon(pageBrand, settings));
  }, [pageBrand, settings]);

  useEffect(() => {
    if (!pathName) return;

    const activeSubLinks = getSubLinks(mainLinks, pathName, settings);
    setSubLinks(activeSubLinks);
  }, [settings, mainLinks, pathName]);

  if (isApp) {
    return null;
  }

  return (
    <SessionProvider session={null}>
      <header>
        <SkipNavContent href="#main-content">
          <Typography color={Color.Hallon1} align="center" type={TypographyType.Body} underlined>
            Gå direkt till huvudinnehållet
          </Typography>
        </SkipNavContent>
        {showBrandSection && (
          <HeaderBrand
            brand={pageBrand}
            b2cText={settings.headerB2ClinkText}
            b2cLink={settings.headerB2Clink}
            b2bText={settings.headerB2BlinkText}
            b2bLink={settings.headerB2Blink}
          />
        )}
        <HeaderMain
          mainLinks={mainLinks}
          mainIconLinks={menuIcons}
          loginIconLink={loginIconLink}
          myPagesIconLink={myPagesIconLink}
          brand={pageBrand}
          sticky={isHeaderSticky}
          isMinimal={isMinimal}
          hasActiveCart={hasActiveCart}
          loggedIn={loggedIn}
        />
        {showHeaderSecondary && (
          <HeaderSecondary sticky={isHeaderSticky} secondaryLinks={subLinks} windowWidth={windowWidth} />
        )}

        <CustomerNotification />
      </header>
    </SessionProvider>
  );
};

export default Header;
